@import 'theme.default';
@import 'theme.neutral';

// Show Information, e.g. Not Found, No Data Found
.main {
  width: 100%;
  min-height: 100%;
  padding: 70px 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: var(--page-font-second-size);
}

h1,
h2 {
  font-family: var(--page-font);
  margin: 0 0 1em;
}

p {
  margin: 0 0 1em;
}

.logo {
  z-index: 99;
  background: url('../assets/yoc-white-logo.svg') no-repeat center/contain;
  font-size: 0;
  line-height: 0;
}

.frame-container {
  display: flex;
}
