.header {
  grid-area: header;
  display: flex;
  position: relative;
  z-index: 1;
  min-height: 72px;
  padding: 10px 30px 10px 32px;
  align-items: center;

  > div > span {
    display: block;
    font: clamp(24px, 2vw, 32px) / 1.5 var(--page-font);
    text-transform: uppercase;
    white-space: nowrap;
  }
}
