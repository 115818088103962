@import '../../components/PreviewScreen/variables'; // devices dimensions

// SPINNER
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background: var(--yoc-white);
}

.logo {
  position: absolute;
  min-height: 52px;
}

.logo-desktop {
  margin: 10px 30px 10px 32px;
}

.logo-mobile {
  margin: 10px 30px 10px 20px;
}
